<template>
    <div class="grid">
        <div class="col-12 md:col-12">
            <div class="card p-fluid">
                <h5>{{ natureza.id ? 'Atualizar Natureza' : 'Nova Natureza' }}</h5>
                <div class="field col-9">
                    <label class="required">Título</label>
                    <InputText type="text" v-model="natureza.title" />
                </div>
                <div class="field col-9">
                    <label>Conceito (caderno técnico)</label>
                    <Textarea v-model="natureza.conceito" :autoResize="true" rows="4" cols="30" />
                </div>
                <div class="field-checkbox col-10">
                    <Checkbox id="binary" v-model="natureza.active" :binary="true" />
                    <label for="binary">Ativo</label>
                </div>
            </div>
            <Button label="Cancelar" @click.stop="cancelar" icon="pi pi-times-circle" class="mr-2 p-button-secondary"></Button>
            <Button label="Salvar" @click.stop="inserir" icon="pi pi-check-circle" :disabled="!natureza.title"></Button>
        </div>
    </div>
</template>
<script>
import Services from './service';

export default {
    data() {
        return {
            natureza: {
                active: true,
                title: null,
                conceito: null,
            },
        };
    },
    mounted() {
        if (this.$route.params.id) {
            Services.obterPorId(this.$route.params.id).then((response) => {
                if (response?.success) {
                    this.natureza = response.data;
                } else {
                    this.natureza = null;
                }
            });
        }
    },

    methods: {
        inserir() {
            this.$store.dispatch('addRequest');

            if (this.natureza.id) {
                Services.atualizar(this.natureza.id, this.natureza).then((response) => {
                    this.respostaSalvar(response, true);
                });
            } else {
                Services.inserir(this.natureza).then((response) => {
                    this.respostaSalvar(response, false);
                });
            }
        },
        respostaSalvar(response, edicao) {
            if (response?.success) {
                this.$toast.add({
                    severity: 'success',
                    summary: 'Natureza',
                    detail: `Natureza ${edicao ? 'editada' : 'inserida'} com sucesso`,
                    life: 3000,
                });

                this.cancelar();
            } else {
                this.$toast.add({
                    severity: 'error',
                    summary: 'Erro',
                    detail: response.errors[0],
                    life: 10000,
                });
            }
            this.$store.dispatch('removeRequest');
        },
        cancelar() {
            this.$router.push({
                name: `Naturezas`,
            });
        },
    },
};
</script>
